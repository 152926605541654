.email-verification-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(90% - 10 * (100vw - 280px) / 15); /* Linear interpolation between 85% and 35% */
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  margin-bottom: 10vw;
}
.email-verification-section h2 {
margin-top: 25px;
}
.email-verification-section p {
width: 80%;
margin-bottom: 30px;
}
