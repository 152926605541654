.projects-section {
  text-align: center;
  margin-top: 50px; /* Adjust as needed */
}

.projects-section h2 {
    color: #8B0000; /* Medium-dark blood red */
    font-size: 32px; /* Adjust font size as needed */
}

.projects-section p {
  font-size: 16px;
  margin-bottom: 20px;
}

.projects-link {
    display: block;
    padding: 12px 30px;
    margin: 0 auto;
    border: 2px solid #8b0000; /* Blood red border */
    text-align: center;
    text-decoration: none;
    color: #8b0000; /* Blood red text color */
    background-color: transparent; /* Transparent background */
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.projects-link:hover {
  background-color: #555;
}

.projects-section {
  text-align: center;
}

.content-container {
  display: flex;
  flex-direction: flex; /* Display items vertically */
  justify-content: center; /* Center items horizontally */
}

.image-container {
  order: 1; /* Change the order of the image container to 2 (default is 0) */
}

.text-container {
  max-width: 40vw;
  order: 2; /* Change the order of the text container to 1 (default is 0) */
  padding: 0 20px; /* Adjust padding as needed */
}

.btn-container {
  margin-top: 20px; /* Adjust margin as needed */
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
  .content-container {
    flex-direction: column; /* Display items vertically */
  }

  .image-container {
    order: 2; /* Change the order of the image container to 1 */
    margin-top: 20px;
  }

  .text-container {
    max-width: 100vw;
    order: 1; /* Change the order of the text container to 2 */
    padding: 0; /* Remove padding */
  }
}
/* Adjust image size */
.fields-image {
    max-width: calc(min(10vw + 200px, 350px));
    height: auto; /* Ensure image maintains aspect ratio */
}
