/* AboutMe.css */

.about-me-container {
    display: flex;
    justify-content: space-between; /* Distribute items along the main axis */
    max-width: 90%; /* Limit the width of the container */
    margin: 0 auto; /* Center the container horizontally */
}

.about-text {
    text-align: left; /* Align text to the left */
    line-height: 1.6; /* Adjust line height for readability */
    width: calc(66.666% - 20px); /* 2/3 of the container width minus padding */
    margin-right: 20px; /* Add spacing between text and image */
}

.about-image {
    width: calc(33.333% - 20px); /* 1/3 of the container width minus padding */
    text-align: center; /* Center the image horizontally */
}

.about-image img {
    max-width: calc(min(10vw + 100px, 250px)); /* Ensure the image does not exceed its container's width */
    height: auto; /* Maintain the aspect ratio of the image */
    border-radius: 10%; /* To create oval shape */
}

/* Apply flexbox layout for smaller screens */
@media (max-width: 1200px) {
    .about-me-container {
        flex-direction: column; /* Display flex items in a column */
    }

    .about-text, .about-image {
        width: 100%; /* Set both text and image containers to full width */
        margin-right: 0; /* Remove margin between text and image */
        margin-bottom: 20px; /* Add spacing between text and image */
    }
}
