.sample-section {
  flex: 1;
  max-width: 15%;
  min-width: 120px;
  max-height: 500px; /* Allow the section to expand vertically based on its content */
}
.sample {
  background-color: rgba(255, 255, 255, 0.5); /* Dark gray color with 90% opacity */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.preview-button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}
.preview-button:hover {
  background-color: #0056b3;
}
.preview-button.clicked {
  animation: buttonClickAnimation 0.3s ease; /* Add a custom animation for the clicked state */
}

@keyframes buttonClickAnimation {
  0% { background-color: #007bff; } /* Start with the original color */
  50% { background-color: #0056b3; } /* Flash to a brighter color halfway through the animation */
  100% { background-color: #007bff; } /* Return to the original color */
}

/* Media query for small screens */
@media (max-width: 768px) {
  .sample-section {
    max-width: 100%; /* Adjust to fit full width */
  }

  .sample {
  }
}