.full-page-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
}
.opencv-page {
  flex: 1; /* Allow the opencv-page section to expand and fill the remaining space */
  padding: 40px 0; /* Add padding as needed */
}
.stream-wrapper {
  display: flex;
  justify-content: space-between;
}

.stream {
  width: calc(30vw - 10px);
  max-width: 640px;
  background-color: rgba(255, 255, 255, 0.6); /* Dark gray color with 90% opacity */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: flex; /* Set display to flex */
  flex-direction: column; /* Stack buttons and content vertically */

}

.stream-content {
  flex: 1; /* Take remaining space */
}

.stream img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px; /* Add slightly rounded corners */
  object-fit: cover; /* Ensure the image covers the entire box */
}

.stream h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #007bff; /* Change header color */
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.effect-button {
  padding: 10px 20px;
  margin: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.effect-button:hover {
  background-color: #0056b3;
}

.view-project-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px; /* Add padding to the button */
  background-color: #007bff; /* Change button background color */
  color: #fff; /* Change button text color */
  border: none; /* Remove button border */
  border-radius: 10px; /* Add border radius to the button */
  cursor: pointer; /* Change cursor to pointer on hover */
  text-decoration: none !important; /* Remove underline from button text */
}

.view-project-button:hover {
  background-color: #0056b3; /* Darken button background color on hover */
  text-decoration: none !important; /* Remove underline from button text */
}


.stream video,
.stream canvas {
  width: 100%; /* Ensure video fills the entire container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Add rounded corners to the video */
  display: block; /* Ensure the video is displayed as a block element */
}


/* Adjustments for small screens */
@media screen and (max-width: 1200px) {
  .opencv-page.tab-mode .stream {
    width: 100%; /* Ensure streams take full width in single tab mode */
  }

  .stream-wrapper {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    flex-wrap: wrap; /* Allow content to wrap */
  }
}

.tab-buttons {
  justify-content: center; /* Center the tab buttons horizontally */
  display: flex;
}

.tab-buttons button {
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-bottom: none;
  border-radius: 15px 15px 0 0;
  padding: 18px 10px;
  cursor: pointer;
}

.tab-buttons button.active {
  background-color: #fff;
}


/* CSS for the loading effect */
.view-project-button.loading {
  background-color: #fff; /* Change background color to white */
  border: 2px solid #007bff; /* Blue border */
  color: #007bff; /* Blue text */
  pointer-events: none; /* Disable pointer events during loading */
}

/* CSS for the animation */
@keyframes scrollAnimation {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.view-project-button.loading {
  animation: scrollAnimation 5s linear infinite; /* Apply animation to the loading button */
}



/* Center the dropdown button */
.effect-dropdown {
  padding: 10px 30px 10px 20px; /* Adjust padding to fit arrow */
  background-color: #fff;
  color: #007bff;
  border: 2px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  position: relative; /* Ensure relative positioning for absolute child */
}

/* Styling for the dropdown arrow when hovering */
.effect-dropdown:hover + .dropdown-arrow {
  border-top-color: #0056b3;
}

/* Center the dropdown button and adjust arrow position */
.dropdown-container {
  position: relative;
  display: inline-block; /* Change to inline-block to fit content width */
  margin-top: 20px;
  justify-content: center; /* Center the button horizontally */
}

.effect-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.effect-dropdown::-ms-expand {
  display: none;
}

/*
.dropdown-container::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 5px; 
  transform: translateY(-50%);
  pointer-events: none;
}*/

/* Styling for the dropdown arrow when hovering over the dropdown */
.dropdown-container:hover::after {
  color: #0056b3;
}

/* Styling for the dropdown options */
.effect-dropdown option {
  background-color: #fff;
  color: #007bff;
}

/* Styling for the dropdown when hovering over options */
.effect-dropdown option:hover {
  background-color: #f0f0f0;
}


.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust right position to fit within button */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #007bff;
}


.button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 20px; /* Add margin as needed */
}

.toggle-button {
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: #007bff; /* Set button background color */
  color: #fff; /* Set button text color */
  border: none; /* Remove button border */
  border-radius: 5px; /* Add border radius */
  cursor: pointer; /* Set cursor to pointer */
  transition: background-color 0.3s ease; /* Add transition effect */
}

.toggle-button:hover {
  background-color: #0056b3; /* Change button background color on hover */
}
