/* Navbar container styles */
.navbar {
  background-color: rgba(0, 0, 0, 0.69); /* Dark gray color with 90% opacity */
  color: #fff; /* Text color */
  padding: 0px 0px; /* Adjust padding as needed */
  display: flex; /* Use flexbox */
  justify-content: flex-end; /* Align items to the right */
  align-items: center; /* Align items vertically */
  height: 60px; /* Set a fixed height for the navbar */
  width: 100%;
  position: relative; /* Add relative positioning for absolute positioning of dropdown */
}


/* Navbar link styles */
.navbar a {
  color: inherit; /* Inherit text color from parent */
  text-decoration: none; /* Remove underline */
  padding: 8px 12px; /* Add padding around each link */
  transition: background-color 0.3s ease; /* Transition effect */
}

/* Hover effect */
.navbar a:hover {
  background-color: #555; /* Medium gray color */
  border-radius: 5px; /* Rounded corners */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-left {
  display: flex;
  align-items: center;
}
.nav-right {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Adjust the value as needed to create extra space */
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.linkedin-logo {
  width: 30px; /* Adjust size of the LinkedIn logo */
  height: auto;
}

.logoff-button {
  background-color: #757575; /* Medium gray */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; /* Adjust margin to position the button */
}

.logoff-button:hover {
  background-color: #616161; /* Darker gray on hover */
}

.nav-left {
  flex: 1; /* Fill remaining space */
}

/* Hamburger menu styles */
.menu-toggle {
  display: none; /* Hide the hamburger menu button by default */
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 9999;
}

.menu-bar {
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.menu-bar.open:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.menu-bar.open:nth-child(2) {
  opacity: 0;
}

.menu-bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}



/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .nav-links {
    display: none; /* Hide the links on smaller screens by default */
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center items horizontally and vertically */
    text-align: center; /* Center text horizontally */
    position: absolute;
    top: 60px;
    right: 20px; /* Position at the right side */
    width: auto; /* Adjust width as needed */
    background-color: #333;
    padding: 10px 0;
    z-index: 999;
  }

  .nav-links.open {
    display: flex; /* Show the links when menu is open */
  }

  .nav-links a {
    color: #fff;
    text-decoration: none;
    padding: 12px 12px;
    transition: background-color 0.3s ease;
  }

  .nav-links a:hover {
    background-color: #555;
  }

  .menu-toggle {
    display: flex; /* Show the hamburger menu button on smaller screens */
  }
}