.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(90% - 10 * (100vw - 280px) / 18); /* Linear interpolation between 85% and 35% */
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  margin-bottom: 1rem;
}
.signup-container form {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the form items horizontally */
  justify-content: center;
  margin-bottom: 30px;
}
.input-container label {
  font-weight: bold;
  text-align: left; /* Align the label text to the left */
}
.input-container {
  width: 95%;
  min-width: 50px;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.signup-container input {
  width: 100%; /* Make input fields fill the container */
  height: 2rem;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  color: black;
  margin-top: 0.25rem;
}
.signup-container button {
  padding: 0.7rem 2rem;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  margin-top: 1rem;
}
.signup-container button:hover {
  opacity: 0.8;
}
.note-message {
  margin-top: 30px;
  padding: 20px;
  width: 70%; /* Adjust the width as needed */
  text-align: center; /* Center the text horizontally */
}
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #000000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.signup-login-button {
  margin-left: 15px; /* Adjust the margin as needed */
}