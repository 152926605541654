.text-input-container {
  width: 100%; /* Ensure the container takes up the full width */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Add margin to separate the text input and the submit button */
}
.summary-text-input {
  width: 80%;
  min-width: 200px; /* Minimum width */
  min-height: 150px; /* Minimum height */
  font-size: 16px;
  max-width: 1000px;
  margin: 0 auto; /* Center the div horizontally */
}
.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px; /* Add margin to separate the text input and the submit button */
}
.submit-button:hover {
  background-color: #0056b3;
}
.submit-button:disabled {
  background-color: #ccc; /* Change background color to gray */
  cursor: not-allowed; /* Change cursor to not-allowed */
}
