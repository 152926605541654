.thank-you-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(90% - 10 * (100vw - 280px) / 15); /* Linear interpolation between 85% and 35% */
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  margin-bottom: 10vw;
}

.main-header {
  width: 90%;
  color: #8b0000; /* Blood red color */
  font-size: 32px; /* Adjust the font size as needed */
  text-align: center; /* Center align the text */
  margin-top: 25px; /* Add some margin between the main header and regular text */
}

.regular-text {
  width: 90%;
  color: #000; /* Black color */
  font-size: 18px; /* Adjust the font size as needed */
  margin-top: 20px; /* Add some margin between the main header and regular text */
  margin-bottom: 30px; /* Add some margin between the main header and regular text */
  text-align: center; /* Center align the text */
}

.thank-you-page {
  display: flex;
  flex-direction: column;
}