.projects-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  height: 100vh;
  max-width: 100%; /* Ensure content doesn't extend past 50% of screen width */
  margin: 0 auto; /* Center the content horizontally */
  flex: 1; /* Allow the content to expand and push the footer to the bottom */
}

.projects-view-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the page takes up the full viewport height */
}

.projects-content {
  flex: 1; /* Fill the remaining space in the container */
  padding: 20px; /* Add padding as needed */
}

.projects-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%; /* Ensure the content takes at least the full height of the viewport */
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Generate as many columns as possible with a minimum width of 300px */
  gap: 20px; /* Gap between columns */
  max-width: calc(100%); /* Limit the maximum width of the grid to the viewport width minus the combined horizontal padding */
  margin: 0 auto; /* Center the grid horizontally */
}

.project {
  border-radius: 20px; /* Add rounded corners */
  border: 3px solid #ccc;
  padding: 20px;
  width: 100%; /* Set a fixed width for each grid item */
  height: 170px; /* Set a fixed height for each grid item */
  background-color: rgba(255, 255, 255, 0.5); /* Transparent white background */
}


.project-column {
  display: flex;
  justify-content: center;
}

.project h2 {
  text-align: center;
}

.project p {
  text-align: center;
  margin-top: 20px;
  width: 100%; /* Limit the width of the text content */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view-project-button {
  display: block;
  margin: 10% auto;
  padding: 10px 20px; /* Add padding to the button */
  background-color: #007bff; /* Change button background color */
  color: #fff; /* Change button text color */
  border: none; /* Remove button border */
  border-radius: 10px; /* Add border radius to the button */
  cursor: pointer; /* Change cursor to pointer on hover */
  text-decoration: none !important; /* Remove underline from button text */
}

.view-project-button:hover {
  background-color: #0056b3; /* Darken button background color on hover */
  text-decoration: none !important; /* Remove underline from button text */
}

.projects-title {
    font-size: 32px; /* Adjust font size as needed */
    margin-bottom: 0px;
}

.projects-description
{
    display: flex;
    justify-content: center;
    max-width: 90%;
    margin-bottom: 20px; /* Add some space below the description */
}

.projects-header {
  width: calc(85% - 1 * (100vw - 300px) / 10); /* Linear interpolation between 85% and 35% */
  background-color: rgba(255, 255, 255, 0.5); /* Transparent white background */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  border-radius: 40px; /* Add rounded corners */
  margin-bottom: 30px;
}
.admin-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-table th, .admin-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.admin-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.admin-table tr:hover {
  background-color: #f1f1f1;
}
