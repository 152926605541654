.contact-form {
    width: calc(85% - 8 * (100vw - 300px) / 18); /* Linear interpolation between 85% and 35% */
    margin: 0 auto;
    text-align: center;
}

.form-group {
    margin-bottom: 10px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
    text-align: left;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.form-group textarea {
    height: 120px; /* Adjust the height as needed */
}

/* Center the reCAPTCHA container */
.recaptcha-container {
    display: flex;
    flex-direction: column; /* Ensure elements stack vertically */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100%; /* Ensure the container takes up the full height */
    padding-bottom: 20px; /* Add padding to create space at the bottom */
}

/* Optional: Adjust the size of the reCAPTCHA widget */
.g-recaptcha {
    transform: scale(0.8); /* Adjust the scale as needed */
}
.contact-form h2 {
    color: #8B0000; /* Medium-dark blood red */
}
