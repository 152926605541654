.transcription-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 20px auto;
}
.transcription-content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.4); /* Dark gray color with 90% opacity */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
  margin: 20px;
}
.page-description {
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
}
.text-input-container {
  width: 100%; /* Ensure the container takes up the full width */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Add margin to separate the text input and the submit button */
}
.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.submit-button:hover {
  background-color: #0056b3;
}
.submit-button:disabled {
  background-color: #ccc; /* Change background color to gray */
  cursor: not-allowed; /* Change cursor to not-allowed */
}
.transcription-wrapper .login-buttons .login-button2,
.transcription-wrapper .login-buttons .signup-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-message {
  margin-bottom: 10px;
}
.transcription-header-section {
  margin-bottom: 40px;
  background-color: #f0f0f0; /* Light gray background for segmentation */
  padding: 20px; /* Add padding for spacing */
  border-radius: 10px; /* Add border radius for rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Add shadow effect */
  width: 100%;
}

.transcription-header-section h1 {
  margin-bottom: 20px;
  position: relative;
}

.transcription-header-section {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.4); /* Dark gray color with 90% opacity */
  padding: 20px; /* Add padding for spacing */
  border-radius: 10px; /* Add border radius for rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Add shadow effect */
  width: 100%;
}
.transcription-header-section h1::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: #007bff;
}
.file-upload,
.voice-recorder,
.transcription {
  width: 100%;
  margin-bottom: 20px;
}

.file-upload h2,
.voice-recorder h2,
.transcription h2 {
  margin-bottom: 10px;
}

.transcription-text {
  min-width: 200px;
  width: 80%; /* Make it take the full width of its container */
  height: 200px; /* Adjust height as needed */
  resize: none; /* Disable textarea resizing */
  margin-bottom: 25px;
}
.transcription-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
button.recording {
  background-color: red;
  color: white;
  border-color: red;
}
/* Update the Choose File button */
.file-upload button,
.voice-recorder button,
.transcription button {
  padding: 8px 16px;
  font-size: 18px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Style the buttons on hover */
.file-upload button:hover,
.voice-recorder button:hover,
.transcription button:hover {
  background-color: #0056b3;
}

/* Style the Choose File button */
.file-upload input[type="file"]::-webkit-file-upload-button {
  padding: 8px 16px;
  font-size: 18px;
  background-color: #808080;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 60px;
}

/* Style the button on hover */
.file-upload input[type="file"]::-webkit-file-upload-button:hover {
  background-color: #0056b3;
}
button[disabled] {
  background-color: #ccc; /* Gray background color */
  color: #666; /* Dark gray text color */
  cursor: not-allowed; /* Not allowed cursor */
  pointer-events: none; /* Disable pointer events */
}

button[disabled]:hover {
  background-color: #ccc; /* Ensure hover color remains gray */
}
.voice-recorder .voice-recorder-button.recording {
  background-color: red;
  color: white;
}
.side-by-side-container-transcription {
  width: 100%; /* Limit the width to 75% */
  display: flex;
  justify-content: space-between;
}
.transcription-elements {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center contents vertically */
  margin: 0 auto;
}