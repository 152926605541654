.sample-section-transcription {
  width: 20vw;
  min-width: 280px;
}
.sample-transcription {
  background-color: rgba(255, 255, 255, 0.5); /* Dark gray color with 90% opacity */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.preview-button-transcription {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}
.preview-button-transcription:hover {
  background-color: #0056b3;
}
.preview-button-transcription.clicked {
  animation: buttonClickAnimation 0.3s ease; /* Add a custom animation for the clicked state */
}

@keyframes buttonClickAnimation {
  0% { background-color: #007bff; } /* Start with the original color */
  50% { background-color: #0056b3; } /* Flash to a brighter color halfway through the animation */
  100% { background-color: #007bff; } /* Return to the original color */
}

/* Media query for small screens */
@media (max-width: 900px) {
  .sample-section-transcription {
    width: 90%; /* Adjust to fit full width */
    margin: 0 auto;
  }

  .sample {
  }
}
.audio-player {
  min-width: 100px; /* Adjust width as needed */
  width: 100%;
}
