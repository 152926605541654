.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(90% - 10 * (100vw - 280px) / 15); /* Linear interpolation between 85% and 35% */
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  margin-bottom: 1rem;
}

.login-heading {
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.login-label {
  width: 90%;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left; /* Align labels to the left */
}

.login-input-email {
  width: 100%; /* Make input fields fill the container */
  height: 2rem;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  color: black;
  margin-top: 0.25rem;
}
.login-input-password {
  width: 100%; /* Make input fields fill the container */
  height: 2rem;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  color: black;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}
.login-button {
  padding: 0.7rem 2rem;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 30px;
}
.login-button:hover {
  opacity: 0.8
}
.login-error {
  color: red;
  margin-bottom: 10px;
}

.login-verification {
  margin-top: 10px;
}

.login-verification-text {
  text-align: center;
  margin-bottom: 10px;
}

.login-verification-button {
  padding: 10px 20px;
  background-image: linear-gradient(to right, #6dd5ed, #2193b0);
  color: white;
  border: none;
  cursor: pointer;
}

.login-signup {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 40px;
}
