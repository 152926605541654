.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
.description {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: #fff; /* White text */
  padding: 10px; /* Add padding for better readability */
  border-radius: 5px; /* Rounded corners */
  font-size: 18px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some space at the bottom */
}