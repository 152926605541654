/* TechGallery.css */

.tech-gallery {
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Adjusted column sizes */
  gap: 20px; /* Gap between grid items */
  max-width: 98%;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.gallery-item img {
  width: 100%; /* Make images fill their grid cell */
  height: auto;
  transition: transform 0.3s ease; /* Smooth transition on hover */
}

.gallery-item:hover img {
  transform: scale(1.10); /* Scale up image on hover */
}

.gallery-item p {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for description */
  color: #fff;
  font-size: 14px;
  text-align: center;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition on hover */
  box-sizing: border-box; /* Include padding in element's total width and height */
  overflow: hidden; /* Hide overflow content */
  white-space: pre-wrap; /* Allow text wrapping */
  max-height: 100%; /* Set maximum height */
  transition: max-height 0.3s ease; /* Smooth transition for height change */
}

.gallery-item:hover p {
  opacity: 1; /* Show description on hover */
  max-height: 100%; /* Remove maximum height on hover */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .tech-gallery {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjusted column sizes for smaller screens */
  }
}
