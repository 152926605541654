/* CSS for Header Section */
.header-container {
    text-align: center; /* Center align all elements within the header */
    margin-top: 0px; /* Adjust margin top as needed */
    width: 100%; /* Set width to 100% */
    z-index: 1; /* Ensure the content stays above the background */
}

.main-title {
    color: #8B0000; /* Medium-dark blood red */
    font-size: 32px; /* Adjust font size as needed */
}

.profile-picture-container {
    margin-top: 30px; /* Adjust margin top as needed */
}

/* CSS for Header Section */
.profile-picture {
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%; /* To create oval shape */
}

/* Calculate scaled percentage based on viewport width */
.profile-picture {
    max-width: calc(12vw + 160px); /* Set a base percentage with additional scaling based on screen width */
}
.cover-letter {
    margin-top: 40px; /* Adjust margin top as needed */
    text-align: left; /* Align cover letter text to the left */
    line-height: 1.6; /* Adjust line height for readability */
}

.cover-letter-container {
    width: 80%; /* Set the width to 80% of the container */
    margin: 0 auto; /* Center the cover letter text horizontally */
}

/* Header.css */

.delimiter {
    width:85%;
    background-color: rgba(248, 248, 248, 0.5); /* Light gray background with 70% opacity */
    padding: 10px;
    margin: 15px auto; /* Center the Header component horizontally */
    border-radius: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
