.resume-images-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Add some space between the two images */
}

.resume-section {
    text-align: center; /* Center-align the content */
}

.resume-title {
    color: #8B0000; /* Medium-dark blood red */
    font-size: 32px; /* Adjust font size as needed */
}

.resume-content {
    /* Style for the resume content container */
}

.resume-image {
    /* Style for the resume image */
    display: block;
    margin: 0 auto; /* Center the image horizontally */
    cursor: pointer;
    max-width: calc(30vw + 160px); /* Set a base percentage with additional scaling based on screen width */

}

/* The Modal (background) */
.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 10px;
    border: 1px solid #888;
    max-width: 90%;
}
.resume-image-full {
  max-width: 100%; /* Ensure the image fills the modal content */
  height: auto; /* Maintain aspect ratio */
}
/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Style the resume image inside the modal */
.resume-image-full {
    height: auto;
    display: block;
    margin: 0 auto; /* Center the image */
}
.btn {
    display: block;
    padding: 8px 20px;
    margin: 0 auto;
    border: 2px solid #8b0000; /* Blood red border */
    text-align: center;
    text-decoration: none;
    color: #8b0000; /* Blood red text color */
    background-color: transparent; /* Transparent background */
    border-radius: 5px;
    border: 2px solid #8b0000; /* Blood red border */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:hover {
    background-color: #8b0000; /* Blood red background on hover */
    color: #fff; /* White text color on hover */
}
/* Resume.css */

/* Container for the download resume button */
.btn-container {
    display: inline-block;
    margin-top: 20px; /* Adjust as needed */
    width: fit-content; /* Adjust as needed */
    padding-bottom: 20px;
}

/* AboutMe.css */

.main-title {
    color: #8B0000; /* Medium-dark blood red */
    font-size: 32px; /* Adjust font size as needed */
    text-align: center; /* Center the text */
    margin-bottom: 20px; /* Add some bottom margin for spacing */
}
