.summary-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}
.summary-content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.4); /* Dark gray color with 90% opacity */
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
  margin: 20px;
}
.summary-page-description {
  width: 100%; /* Set width to 100% */
  margin: 0 auto; /* Center horizontally */
}
.summary-content p {
  text-align: center; /* Center the text horizontally */
  width: 85%;
  margin: 0 auto; /* Center the paragraph horizontally */
  margin-bottom: 40px;
}
.main-container.summary-login .login-buttons .login-button2,
.main-container.summary-login .login-buttons .signup-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.side-by-side-container {
  display: flex;
  justify-content: space-between;
}
.SummarizationContent {
  flex: 3; /* Take up a larger portion of the container */
  max-width: 80%; /* Limit the width to 75% */
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Arrange children in a column */
  justify-content: center; /* Center contents horizontally */
  margin: 0 auto; /* Center the container itself horizontally */
}

.login-message {
  margin-bottom: 10px;
}
.summary-header-section {
  width: 100%; /* Set width to 100% */
  display: flex; /* Use flexbox */
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
}
.summary-header-section h1 {
  margin-bottom: 20px;
  position: relative;
}
.summary-header-section h1::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background-color: #007bff;
}
.summary-header-section-contained
{
  margin-bottom: 40px;
  background-color: rgba(255, 255, 255, 0.3); /* Dark gray color with 90% opacity */
  padding-bottom: 1px; /* Add padding for spacing */
  padding-top: 1px; /* Add padding for spacing */
  border-radius: 10px; /* Add border radius for rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Add shadow effect */
  width: 80%;
  min-width: 250px;
}




/* Adjustments for small screens */
@media screen and (max-width: 768px) {
  .summary-wrapper {
    flex-direction: column; /* Stack tabs vertically on small screens */
  }

  .stream-wrapper {
    display: flex;
    justify-content: center; /* Center tab content horizontally */
    flex-wrap: wrap; /* Allow tab content to wrap */
  }

  .stream {
    width: 100%; /* Take full width of container */
    display: none; /* Hide tab content by default */
  }

  .stream.active {
    display: block; /* Show tab content if active */
  }
}
.summary-tabs {
  background-color: rgba(255, 255, 255, 0.5); /* Dark gray color with 90% opacity */
  padding-bottom: 1px; /* Add padding for spacing */
  padding-top: 1px; /* Add padding for spacing */
  border-radius: 10px; /* Add border radius for rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Add shadow effect */
}