.footer {
  margin-top: auto; /* Push the footer to the bottom of the container */
  background-color: rgba(0, 0, 0, 0.69); /* Dark gray color with 90% opacity */
  height: 50px;
  width: 100%;
  max-width: 100%; /* Ensure footer stretches only as far as the screen width */
}

.footer-text {
  text-align: center;
  color: #fff;
  font-size: 14px;
  text-align: left;
  margin-left: 10px; /* Add left margin */
}

.footer-image {
  position: absolute;
  right: 0;
  width: 45px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  transform: translateY(-100%); /* Adjust vertical position */
  right: 20px; /* Adjust horizontal position */
}
